










import { Component, Prop, Vue } from 'vue-property-decorator'
import Display from '@/components/molecules/Display.vue'

@Component({
  components: {
    Display,
  },
})
export default class PosterWidget extends Vue {
  @Prop()
  model!: any

  @Prop()
  index!: number

  @Prop()
  active!: boolean

  @Prop()
  intersect!: boolean
}
