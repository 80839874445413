



















import { Component } from 'vue-property-decorator'
import { sustainabilityService } from '@/services'
import ArticleWidget from '@/components/organsims/widgets/Article.vue'
import GridboxWidget from '@/components/organsims/widgets/Gridbox.vue'
import PosterWidget from '@/components/organsims/widgets/Poster.vue'
import CoverWidget from '@/components/organsims/widgets/Cover.vue'
import Layout from '@/components/organsims/Layout.vue'
import View from '@/mixins/View'

@Component({
  components: {
    ArticleWidget,
    GridboxWidget,
    PosterWidget,
    CoverWidget,
    Layout,
  },
})
export default class Sustainability extends View {
  widgets: any = []

  getWidgetId(anchor: any) {
    return anchor ? anchor.name : undefined
  }

  scroll() {
    if (!this.$state.needsLogo && !this.$state.smoothScroll) this.$state.needsLogo = true
  }

  async created() {
    this.widgets = await this.fetch(sustainabilityService)
  }
}
